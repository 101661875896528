<template>
  <CRow>
    <CCard class="col-xl-12 col-md-12">
      <CCardHeader>
        <strong>Öğretmen Kazançları</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
            class="mb-0 table-outline"
            hover
            :items="tableItems"
            :fields="tableFields"
            head-color="light"
            no-sorting
            table-filter
            items-per-page-select
            :items-per-page="itemPerPage"
            border
        >
          <td slot="avatar" class="text-center" slot-scope="{item}">
            <div class="c-avatar">
              <img :src="item.avatar.url" class="c-avatar-img" alt="">
            </div>
          </td>
          <td slot="teacher" slot-scope="{item}">
            <div>{{ item.teacher }}</div>
            <div class="small text-muted">

            </div>
          </td>
          <td slot="daterange" slot-scope="{item}">
            <div class="clearfix">
            </div>
            <div>{{ item.daterange }}</div>
          </td>
          <td
              slot="alltimes"
              slot-scope="{item}"
              class="text-center"
          >
            <div>{{ item.alltimes }}</div>
          </td>
          <td
              slot="thisweek"
              slot-scope="{item}"
              class="text-center"
          >
            <div>{{ item.thisweek }}</div>
          </td>
          <td slot="manage">
            <CSelect
                size="l"
                horizontal
                :options="options"
                placeholder="option 0"
                custom
            />
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
  </CRow>
</template>

<script>
// import {SubscriptionAdministration} from "@/services/api-service";
export default {
  name: "Payments",
  components: {},
  data() {
    return {
      isDataLoading: false,
      currentPage: 1,
      itemPerPage: 5,
      options: ['Option 1', 'Option 2', 'Option 3'],
      tableItems: [
        {
          avatar: {url: 'img/avatars/1.jpg'},
          teacher: 'Adam Alister',
          daterange: '2012/01/01 - 2012/01/01',
          alltimes: '64 TL',
          thisweek: '64 TL'
        },
        ],
      tableFields: [
        {key: 'avatar', label: ''},
        {key: 'teacher', label: 'Öğretmen'},
        {key: 'daterange', label: 'Tarih Aralığı'},
        {key: 'alltimes', label: 'Tüm Zamanlar', _classes: 'text-center'},
        {key: 'thisweek', label: 'Bu Hafta Kazancı', _classes: 'text-center'},
        {key: 'manage', label: 'Ödeme Durumu'},
      ]
    }
  },
  // TODO: ödeme ile alakalı bir şey yok gibi geldi.
  methods: {
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    /*
    async loadSubscriptionList () {
      this.isDataLoading = true;
      const result = await SubscriptionAdministration.listSubscriptionOptionsForManagement();
      this.tableItems = result.data.pricePerMinute
    }
    */
  }
}
</script>

