var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCard',{staticClass:"col-xl-12 col-md-12"},[_c('CCardHeader',[_c('strong',[_vm._v("Öğretmen Kazançları")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":"","table-filter":"","items-per-page-select":"","items-per-page":_vm.itemPerPage,"border":""},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img",attrs:{"src":item.avatar.url,"alt":""}})])])}},{key:"teacher",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.teacher))]),_c('div',{staticClass:"small text-muted"})])}},{key:"daterange",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"}),_c('div',[_vm._v(_vm._s(item.daterange))])])}},{key:"alltimes",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(item.alltimes))])])}},{key:"thisweek",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(item.thisweek))])])}}])},[_c('td',{attrs:{"slot":"manage"},slot:"manage"},[_c('CSelect',{attrs:{"size":"l","horizontal":"","options":_vm.options,"placeholder":"option 0","custom":""}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }